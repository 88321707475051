/**
 * api server
 * 普通数据api
 */
import { httpPost, httpGet } from 'src/utils/httpRequest';
import request from '../utils/grpcRequest';
import { getConfig } from './server-url-config/env';

let config = getConfig();
let serviceUrl = `${config.apiUrl.apiGatewayPrefix}${config.apiUrl.bagLog}`;

export const urlPrefix = `${config.apiUrl.prefix}`;
const apiGatewayPrefix = `${config.apiUrl.apiGatewayPrefix}`;

function requestApiGatewayPrefixWrap(path, options, redirect) {
  return request(apiGatewayPrefix + path, { method: 'POST', mode: 'cors', body: {}, ...options }, redirect);
}

function requestWrap(path, options, redirect) {
  return request(serviceUrl + path, { method: 'POST', mode: 'cors', body: {}, ...options }, redirect);
}

export async function getBagLogList(param) {
  return requestWrap(
    `/records?${param}`,
    {
      method: 'GET',
    },
    false,
  );
}

export async function getHistoryBagLogList(param) {
  return requestWrap(
    `/historyRecords?${param}`,
    {
      method: 'GET',
    },
    false,
  );
}

export async function getAutoUploadRecord(param) {
  return requestWrap(
    `/records/robots/${param}:getUploadRecordEnable`,
    {
      method: 'GET',
    },
    false,
  );
}

export async function setAutoUploadRecord(sn,param) {
  return requestWrap(
    `/records/robots/${sn}:setUploadRecordEnable`,
    {
      method: 'POST',
      body: {
        "enable": param
      }
    },
    false,
  );
}

export async function uploadOrPull(recordId) {
  return requestWrap(`/records/${recordId}:fetch`, {}, false);
}

export async function downloadBagOrLog(body) {
  return requestWrap(
    `/records/${body.recordId}:download?region=${body.region}`,
    {
      method: 'GET',
    },
    false,
  );
}

export async function multiUploadOrPull(body) {
  return requestWrap(`/records:batchFetch`, { body: { ...body } }, false);
}

export async function syncBagOrLog(body) {
  return requestWrap(`/records/${body.recordId}:sync`, {
    body: {
      "region": body.region
    }
  }, false);
}

export async function downloadRecords(body){
  return httpPost(`${serviceUrl}/records:download`, body, true)
}

export async function SyncBatchRecords(body){
  return httpPost(`${serviceUrl}/records:syncBatch`, body, true)
}

export async function createManifest(body) {
  return requestWrap(`/manifests`, { body: { ...body } }, false);
}

export async function getManifests(param) {
  return requestApiGatewayPrefixWrap(
    `/tempo/v1beta1/aggManifests?${param}`,
    {
      method: 'GET',
    },
    false,
  );
}

export async function getManifest(id, queryString) {
  return requestApiGatewayPrefixWrap(
    `/tempo/v1beta1/aggManifests/${id}/records${queryString}`,
    {
      method: 'GET',
    },
    false,
  );
}

export async function getManifestInfo(id) {
  return requestApiGatewayPrefixWrap(
    `/tempo/v1beta1/manifests/${id}:fetch`,
    {
      method: 'GET',
    },
    false,
  );
}

//获取上传信息
export async function getUploadInfo(bussinessCode, params) {
  return httpPost(`${serviceUrl}/user/records:batchUpload`, 
    {
      sourceType: "web",
      bussinessCode: bussinessCode,
      records: params
    },
  )
}

//查询数据回流计划列表
export async function listPageUploadPlans(body) {
  return httpPost(
    `${serviceUrl}/uploadPlans:listPage`,
    body,
    true
  )
}

//获取临时下载信息
export async function getDownloadInfo(id) {
  return httpPost(`${serviceUrl}/user/records:permission`, 
    {
      recordIds: [id]
    },
  )
}

//获取网络信息
export async function getRobotNetworkResource(sn) {
  return httpGet(
    `${serviceUrl}/robots/${sn}:getRobotNetworkResource`,
    true
  )
}

//变更计划状态
export async function setUploadPlanEnableStatus(name, body ) {
  return httpPost(
    `${serviceUrl}/${name}:SetUploadPlanEnableStatus`,
    body,
    true
  )
}

//创建数据回流计划列表
export async function batchCreateUploadPlans(body) {
  return httpPost(
    `${serviceUrl}/uploadPlans:batchCreate`,
    body,
    true
  )
}

//获取数据回流的文件路径
export async function getSupportTargetPaths(body) {
  return httpGet(
    `${serviceUrl}/supportTargetPaths`,
    true
  )
}